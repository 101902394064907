import React from 'react'
import { Link } from 'react-router-dom'
import Image from '../assets/7392521.jpg'

const MainHeader = () => {
  return (
    <header className = "main__header">
        <div className='container main__header-container'>
            <div className='main__header-left'>
                <h4>Hello. You've entered the home of</h4>
                <h1>01D5D</h1>
                <p>
                    &nbsp;&nbsp;My name is Yi Xie, I'm passionate about game and web development. Dear recruiters, please check out my Skills page for more info about my work experiences and projects.
                </p>
                <p>
                    &nbsp;&nbsp;If you are a lost traveller entered here by accident, please have some hot cocoa in the little tent, it can be cold out there. And while you're here, our team is currently working on a 2D pixel game project, DEMO will soon be avaliable! So, you know, please visit us again in the future :D.
                </p>
                <p>
                    &nbsp;&nbsp;If you are here to checkout options for your new website or new handwoven designer baskets, please check out the Products page and feel free to contact me!
                </p>
                <h5>
                    Oh I also take game ranking commissions for a variety of MOBAs or MMORPGs just so you know...
                </h5>
                {/*<Link to="/plans" className='btn lg'>Get Started</Link>*/}
            </div>
            <div className='main__header-right'>
                <div className="main__header-circle"></div>
                {/*<div className="main__header-image">
                    <img src={Image} alt = "Main Header Image" />
                </div>*/}
            </div>
        </div>
    </header>
  )
}

export default MainHeader
