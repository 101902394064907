import MainHeader from '../../components/MainHeader'
import Footer from '../../components/Footer'


import './home.css'
const Home = () => {
  return (
    <>
    <MainHeader />
    {/*<Footer />*/}
    </>
  )
}

export default Home
